import React from 'react';
import Navbar from '../components/Navbar'
import Content from '../components/Content';
import Footer from '../components/Footer';

function Home() {
    return (<>
        <Navbar></Navbar>
        <Content></Content>
        <Footer></Footer>
        </>
        
    );
}

export default Home;