import React from 'react';

function AboutIntro() {
  return (
    <div className="about-intro">
      <p>
Hola! I'm Carlos.
      </p>
      <p>
      <br>
      </br>
      {/* From Colombia
      Travelled South America as a street artistis
      Empiric
      Amsterdam
      Malmö
      Cirkusskola */}
      </p>
      <p>
      </p>
      <p>
      </p>
    </div>
  );
}

export default AboutIntro;