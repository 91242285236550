import React from "react";
import { Link } from "react-router-dom";
//import Button from "./Button";
import "./Navbar.css";

function Navbar() {
  //const [click, setClick] = useState(false);
  //const [button, setButton] = useState(false);

  //const handleClick = () => setClick(!click);
  //const closeMobileMenu = () => setClick(false);

  //   const showButton = () => {
  //     //if (window.innerWidth < 660) {
  //     //    setButton(false);
  //     //} else {
  //     // setButton(true);
  //     //}
  //   };

  //   useEffect(() => {
  //     showButton();
  //   }, []);

  // window.addEventListener("resize", showButton);

  return (
    <>
      <nav className="navbar">
        <div className="navbar-container">
          <Link to="/" className="navbar-logo">
            Carlos Mario Lopez
          </Link>

          <ul className={"nav-menu"}>
            <li className="nav-item">
              {/*<Link to="/about" className="nav-links">
                About
  </Link>*/}
            </li>
          </ul>
          {/* //{button && <Button buttonStyle='btn--outline'>CONTACT</Button>} */}
        </div>
      </nav>
    </>
  );
}

export default Navbar;
