import React from "react";
import "./Footer.css";

const copyrightString =
  "Copyright " + new Date().getFullYear() + ", Carlos Mario Lopez";
console.log(copyrightString);

function Footer() {
  return (
    <>
      <div className="copywrite-footer">
        <footer> &copy; {copyrightString} </footer>
      </div>
    </>
  );
}

export default Footer;
