import React from 'react';
import './About.css'; // Import the CSS styles

// Import your custom components for the About page sections
import AboutIntro from './AboutIntro';
function AboutContent() {
  return (
    <div className="about-container">

      {/* Introduction section */}
      <AboutIntro />

    </div>
  );
}

export default AboutContent;