const colors = {
  black: "#000",
  white: "#fff",
  pink: "#E493B3",
  cream: "#F1DEDE",
  gray: "#2A2B2E",
};

export const COLORS = {
  background: colors.cream,
  column: colors.pink,
  columnText: colors.gray,
  navbarBackground: colors.purple,
  navbarText: colors.white,
  navbarHover: colors.cream,
};

export default COLORS;
