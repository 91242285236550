import { createRoot } from "react-dom/client";

import App from "./App";
import { version } from "react";

console.log(version);

const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<App tab="" />);
