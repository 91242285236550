import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import AboutContent from '../components/AboutContent';


function About() {
    return (<>
            <Navbar></Navbar>
            <AboutContent></AboutContent>
            <Footer></Footer>
    </>
    );
}

export default About;