import React from "react";
import "./Content.css";
import GalleryItem from "./Gallery";
import kermes from "../images/kermes.jpg";
import westerpark from "../images/westerpark.jpg";
import crystal from "../images/crystal.jpg";
import monociclo from "../images/monociclo.jpg";
import planetarium from "../images/planetarium.jpg";
import pataparriba from "../images/pataparriba.jpg";
import playa from "../images/playa.jpg";
import hula from "../images/hula.jpg";
import w1 from "../images/w1.jpg";
import AboutContent from "./AboutContent";

function Content() {
  return (
    <>
      <div className="container">
        <div className="grid-container">
          <div>
            {/* <div class="flexbox-item">
                        <GalleryItem
                            filePath = {ruigaard1}
                            style = {{width: '10%', height:'10%'}}
                            isVideo = {true} >
                        </GalleryItem>
                        </div> */}
            {/*<div className="flexbox-item">
            {/* Carlos juggles. Starting as a street performer in Colombia, 
            he traveled the south american continent before moving to Europe in 2020, where he now amazes crowds with his specialty: juggling atop a giraffe unicycle.  */}
              <div className="flexbox-item">
              <GalleryItem
                filePath={playa}
                style={{ width: "100%", height: "100%" }}
              ></GalleryItem>
              Mar del Plata, Argentina
            </div>
            <div className="flexbox-item">
              <GalleryItem
                filePath={pataparriba}
                style={{ width: "100%", height: "80%" }}
              ></GalleryItem>
            </div>
            <div className="flexbox-item">
              <GalleryItem
                filePath={planetarium}
                style={{ width: "100%", height: "80%" }}
              ></GalleryItem>
              Buenos Aires, Argentina
            </div>
            <div className="flexbox-item">
              <GalleryItem
                filePath={kermes}
                style={{ width: "100%", height: "80%" }}
              ></GalleryItem>
              Buenos Aires, Argentina
            </div>


            <div className="flexbox-item">
              <GalleryItem
                filePath={crystal}
                style={{ width: "100%", height: "100%" }}
              ></GalleryItem>
            </div>


            </div>

          <div>
            
          <div className="flexbox-item">
              <GalleryItem
                filePath={monociclo}
                style={{ width: "100%", height: "100%" }}
              ></GalleryItem>
            </div>
            <div className="flexbox-item">
              <GalleryItem
                filePath={w1}
                style={{ width: "100%", height: "100%" }}
              ></GalleryItem>
              Amsterdam, Netherlands
            </div>
            <div className="flexbox-item">
              <GalleryItem
                filePath={hula}
                style={{ width: "100%", height: "100%" }}
              ></GalleryItem>
              Landjuweel Festival, Netherlands
            </div>

            <div className="flexbox-item">
              <GalleryItem
                filePath={westerpark}
                style={{ width: "100%", height: "100%" }}
              ></GalleryItem>
              <br></br>
              Amsterdam, Netherlands
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Content;
